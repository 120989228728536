/* Import for bootstrap */
@import "~bootstrap/dist/css/bootstrap.min.css";
@import '~material-icons/iconfont/material-icons.css';

/* Import for ag-grid */
@import "ag-grid-community/styles/ag-grid.css";
@import "ag-grid-community/styles/ag-theme-alpine.css";

/* Import for font-family Graphik-Semibold */
@font-face {
  font-family: Graphik-Semibold;
  src: url("~src/assets/fonts/Graphik-Semibold.ttf");
}

.not-popover {
  position: absolute !important;
  min-height: 20px !important;
  border: 1px solid #837F89 !important;
  border-radius: 0 !important;
  overflow-x: hidden !important;
  width: 305px !important;
  background-color: #fff !important;
  left: -128px !important;
  top: 30px !important;
  filter: drop-shadow(1px 1px 5px #777) !important;
}

.help-popover {
  position: absolute !important;
  min-height: 20px !important;
  border: 1px solid #837F89 !important;
  border-radius: 0 !important;
  overflow-x: hidden !important;
  width: 305px !important;
  background-color: #fff !important;
  left: -259px !important;
  top: 30px !important;
  filter: drop-shadow(1px 1px 5px #777) !important;
}

.tool-popover {
  position: absolute !important;
  min-height: 20px !important;
  border: 1px solid #837F89 !important;
  border-radius: 0 !important;
  overflow-x: hidden !important;
  width: 305px !important;
  background-color: #fff !important;
  left: -260px !important;
  top: 30px !important;
  filter: drop-shadow(1px 1px 5px #777) !important;
}


@font-face {
  font-family: Graphik-Regular;
  /* font-style: normal; */
  src: url('~src/assets/fonts/Graphik-Regular-Web.eot');
  src: url('~src/assets/fonts/Graphik-Regular.ttf') format('truetype'), url('~src/assets/fonts/Graphik-Regular-Web.woff') format('woff');
}

html,
body {
  background-color: #f9f9f9;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
  font-family: Graphik-Regular !important;
  font-weight: 500 !important;
  overflow-x: hidden;
}

.no-select {
  -webkit-user-drag: none;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

/* html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; } */

.maindivcontainer {
  padding-right: 30px;
  padding-left: 30px;
  background-color: #F9F9F9
}

.QADSemiboldTitle {
  font-size: 18px;
  font-family: Graphik;
  font-weight: 600;
  color: #000000;
}

.QADMediumFieldLabel {
  font-size: 16px;
  font-family: Graphik;
  font-weight: 500;
  color: #333333;
}

.QADMediumText {
  font-size: 14px;
  font-family: Graphik;
  font-weight: 500;
  color: #333333;
}

.QADRegularFieldText {
  font-size: 14px;
  font-family: Graphik;
  font-weight: 400;
  color: #4F4B53;
}

/* Datepicker control custom css start */
.mdc-text-field {
  height: 40px !important;
  width: 100% !important;
  border-top: 1px solid #837F89 !important;
  border-left: 1px solid #837F89 !important;
  border-right: 1px solid #837F89 !important;
  /*border-bottom: 1px solid #837F89 !important;*/
}

.mat-step-icon {
  width: 24px;
  height: 24px;
  margin-right: 1px;
  flex: none;
}

.mat-step-label {
  font-size: 14px !important;
  color: #333333 !important;
  font-weight: 500 !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: #ffffff !important;
}

.mat-mdc-form-field-infix {
  min-height: 40px !important;
}

.mat-mdc-form-field-subscript-wrapper {
  display: none;
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 10px !important;
  padding-bottom: 8px;
}

.mat-mdc-icon-button.mat-mdc-button-base {
  padding-top: 4px;
}
/* Datepicker control custom css end*/

.mat-horizontal-stepper-header {
  pointer-events: none !important;
}

/*mat-step-header[ng-reflect-state="none"] > div[class="mat-step-icon mat-step-icon-state-none"] {
  height: 10px;
  width: 10px;
  background-color: darkgrey;
  
}

mat-step-header[ng-reflect-state="none"] > div[class="mat-step-label mat-step-label-active"]  {
  color: darkgrey !important;
}
div.mat-step-icon.mat-step-icon-state-edit {
  background-image: url("assets/img/NominationForm/Radio_button_checked.svg") !important;

}
mat-step-header[ng-reflect-state="edit"] > .mat-step-icon {
  background-color: #f9f9f9;
  background-image: url("assets/img/NominationForm/Radio_button_checked.svg");
}
mat-step-header[ng-reflect-state="done"] > .mat-step-icon {
  background-color: #f9f9f9;
  background-image: url("assets/img/NominationForm/Circle_with_checkmark_filled.svg");
}
mat-step-header[ng-reflect-state="edit"] ~ div[class="mat-stepper-horizontal-line ng-tns-c10-0 ng-star-inserted"] {
  border-top-width: 1px;
  border-top-style: solid;
  border-color: darkgrey !important;
}

.mat-stepper-horizontal-line {
  border-top-width: 1px;
  border-top-style: solid;
  border-color: #707070;
}


mat-step-header[ng-reflect-state="edit"]  div[class="mat-step-icon-content"] {
  color: #A100FF !important;
}

div[class="mat-step-icon mat-step-icon-state-edit"] > div[class="mat-step-icon-content"] > mat-icon {
  font-size: 28px;
  overflow: visible;
  height: 28px;
  width: 28px;
}
*/

.mat-step-header-state-none > .mat-step-icon {
  height: 10px;
  width: 10px;
  background-color: darkgrey;
}

.mat-step-header-state-none > .mat-step-label {
  color: darkgrey !important;
}

div.mat-step-icon.mat-step-icon-state-done {
  background-color: #f9f9f9;
  background-image: url("assets/img/NominationForm/Circle_with_checkmark_filled.svg");
}
/*div.mat-step-icon.mat-step-icon-state-done.mat-step-icon-selected {
  background-color: #f9f9f9;
  background-image: url("assets/img/NominationForm/Circle_with_checkmark_filled.svg");
}*/
div.mat-step-icon.mat-step-icon-state-edit {
  background-color: #f9f9f9;
  background-image: url("assets/img/NominationForm/Radio_button_checked.svg");
}

div.mat-step-icon.mat-step-icon-state-none {
  height: 10px;
  width: 10px;
  background-color: darkgrey;
}

div[class="mat-step-icon mat-step-icon-state-none"] + div[class="mat-step-label mat-step-label-active"] {
  color: darkgrey !important;
}
/*.mat-step-header-state-edit ~ .mat-stepper-horizontal-line {
  border-top-width: 1px;
  border-top-style: solid;
  border-color: darkgrey !important;
}*/
.mat-stepper-horizontal-line {
  border-top-width: 1px;
  border-top-style: solid;
  border-color: #707070;
}

.mat-step-icon-content {
  position: fixed !important;
}

.mat-step-header-state-edit.mat-step-icon-content {
  color: #A100FF !important;
}

  .mat-step-header-state-edit.mat-step-icon-content > mat-icon {
    font-size: 28px;
    overflow: visible;
    height: 28px;
    width: 28px;
  }

.skillstemplate .modal-content {
  border: 1px solid #837F89;
  border-radius: 0px !important;
  position: relative;
  min-width: 570px;
  background-color: #ffffff;
}

  .skillstemplate .modal-content:after {
    content: "";
    background-color: #FFFFFF !important;
    top: 210px;
    width: 16px;
    height: 17px;
    position: absolute;
    margin-left: 0;
    bottom: 0;
    left: 0;
    box-sizing: border-box;
    border: 1px solid #837F89;
    border-color: transparent transparent #837F89 #837F89;
    transform-origin: 0 0;
    transform: rotate(45deg);
  }

.mat-mdc-checkbox.mat-accent {
  --mdc-checkbox-selected-focus-icon-color: #A100FF !important;
  --mdc-checkbox-selected-hover-icon-color: #A100FF !important;
  --mdc-checkbox-selected-icon-color: #A100FF !important;
  --mdc-checkbox-selected-pressed-icon-color: #A100FF !important;
  --mdc-checkbox-unselected-focus-icon-color: 2.3px solid #4F4B53 !important;
  --mdc-checkbox-unselected-hover-icon-color: 2.3px solid #4F4B53 !important;
  /*--mdc-checkbox-disabled-selected-icon-color: 2.3px solid #4F4B53 !important;
  --mdc-checkbox-disabled-unselected-icon-color: 2.3px solid #4F4B53 !important;*/
  --mdc-checkbox-unselected-icon-color: 2.3px solid #4F4B53 !important;
  --mdc-checkbox-unselected-pressed-icon-color: 2.3px solid #4F4B53 !important;
  --mdc-checkbox-unselected-hover-state-layer-color: white !important;
  --mdc-checkbox-selected-checkmark-color: white !important;
}

  .mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
    background: none !important;
  }

.mat-mdc-checkbox .mdc-checkbox__ripple {
  background: none !important;
}

.mdc-checkbox__ripple {
  background: none !important;
}

.mat-mdc-checkbox .mdc-checkbox {
  margin-top: calc((var(--mdc-checkbox-state-layer-size, 23px) - var(--mdc-checkbox-state-layer-size, 40px)) / 2) !important;
  padding-left: calc((var(--mdc-checkbox-ripple-size, 24px) - 18px) / 2) !important;
  opacity: 0.6
}

.mdc-form-field > label {
  top: 0px !important;
}

.mdc-form-field {
  font-size: 16px !important;
  font-family: Graphik !important;
  font-weight: 400 !important;
  color: #333 !important;
}

.mat-mdc-checkbox label {
  cursor: pointer;
  width: 438px;
  color: #333;
  font-weight: 400;
}

.close.mat-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  line-height: 14px;
  min-width: auto;
}
/*  CSS changes from Devi/Aleesha end*/

.mat-mdc-standard-chip {
  display: none !important;
  --mdc-chip-elevated-container-color: none !important;
  --mdc-chip-elevated-disabled-container-color: none !important;
  background-color: none !important;
  margin-top: -35px !important;
}

.mat-mdc-chip-focus-overlay {
  background: none !important;
}
.notif-modal .modal-content {
  border: 1px solid rgba(0,0,0,0);
}
